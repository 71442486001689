<template>
    <div :class="['domain-top-nav-bar', layout]">
        <div class="desktop-domain-selector">
            <DomainSelector />
        </div>
        <div class="mobile-domain-selector">
            <div class="trigger" @click="mobileDomainSelectorOpen = !mobileDomainSelectorOpen">
                <Avatar
                    v-if="accountName"
                    :color="accountColor"
                    :platform="accountPlatform"
                    show-account-type
                >
                    {{ getDomainInitials(accountName) }}
                </Avatar>
                <Skeleton v-else :width="36" :height="36" />
                <p v-if="accountName" class="client-name" :style="{ color: accountColor }">
                    {{ accountName }}
                </p>
                <SelectArrowIcon
                    v-if="accountName"
                    :hex="accountColor"
                    class="client-selector-arrow"
                />
                <div class="client-selector-skeleton" v-else>
                    <Spacer width="0.75rem" />
                    <Skeleton :width="Math.random() * 80 + 100" />
                </div>
            </div>
            <div v-if="mobileDomainSelectorOpen">
                <div
                    v-for="(account, index) in miniDomainList"
                    id="clientItem"
                    :key="account.accountId"
                    class="select-client-dropdown-client-mobile"
                    :style="{ color: account.color }"
                    @click="changeDomain(account.accountId)"
                >
                    <Avatar :color="account.color" :platform="account.platform" show-account-type>
                        {{ getDomainInitials(account.name) }}
                    </Avatar>
                    <span class="select-client-dropdown-client-name">
                        {{ account.name }}
                    </span>
                    <span class="select-client-dropdown-client-imps">
                        {{ account.tasksAvailable }}
                    </span>
                </div>
            </div>
        </div>

        <div v-if="!mobileDomainSelectorOpen" class="domain-top-nav-bar-center">
            <!-- Improvements -->
            <router-link
                id="navImprovements"
                :to="{ name: Routes.Improvements }"
                class="top-nav-bar-link"
                tabindex="-1"
                @click="isMobileContext ? closeMobileNav(Routes.Improvements) : null"
            >
                <ImprovementsIcon class="top-nav-bar-icon" :color="accountColor" />
                <span
                    v-if="accountColor"
                    class="top-bar-nav-link-text"
                    :style="{ color: accountColor }"
                >
                    Improvements
                </span>
                <Skeleton v-else :width="104" :height="12" class="top-bar-nav-link-text" />
            </router-link>

            <!-- Performance -->
            <router-link
                id="navPerformance"
                :to="{ name: Routes.Performance }"
                class="top-nav-bar-link"
                tabindex="-1"
                @click="isMobileContext ? closeMobileNav(Routes.Performance) : null"
            >
                <PerformanceIcon class="top-nav-bar-icon" :color="accountColor" />
                <span
                    v-if="accountColor"
                    class="top-bar-nav-link-text"
                    :style="{ color: accountColor }"
                >
                    Performance
                </span>
                <Skeleton v-else :width="94" :height="12" class="top-bar-nav-link-text" />
            </router-link>

            <!-- Reports -->
            <router-link
                v-if="!isMicrosoft && !isMeta"
                id="navReports"
                :to="{ name: Routes.Reports }"
                class="top-nav-bar-link"
                tabindex="-1"
                @click="isMobileContext ? closeMobileNav(Routes.Reports) : null"
            >
                <ReportsIcon class="top-nav-bar-icon" :color="accountColor" />
                <span
                    v-if="accountColor"
                    class="top-bar-nav-link-text"
                    :style="{ color: accountColor }"
                >
                    Reports
                </span>
                <Skeleton v-else :width="56" :height="12" class="top-bar-nav-link-text" />
            </router-link>
            <div v-else id="navReports" class="top-nav-bar-link link-disabled">
                <ReportsIcon class="top-nav-bar-icon opteo-steel" />
                <span
                    v-if="accountColor"
                    class="top-bar-nav-link-text"
                    :style="{ color: '#E0E0E5' }"
                    >Reports</span
                >
                <Skeleton v-else :width="94" :height="12" class="top-bar-nav-link-text" />
            </div>

            <!-- Toolkit -->
            <router-link
                v-if="!isMicrosoft && !isMeta"
                id="navCampaigns"
                :to="{ name: Routes.Toolkit }"
                class="top-nav-bar-link"
                tabindex="-1"
                @click="isMobileContext ? closeMobileNav(Routes.Toolkit) : null"
            >
                <TargetsIcon class="top-nav-bar-icon" :color="accountColor" />
                <span
                    v-if="accountColor"
                    class="top-bar-nav-link-text"
                    :style="{ color: accountColor }"
                >
                    Toolkit
                </span>
                <Skeleton v-else :width="48" :height="12" class="top-bar-nav-link-text" />
            </router-link>
            <div v-else id="navCampaigns" class="top-nav-bar-link link-disabled">
                <TargetsIcon class="top-nav-bar-icon opteo-steel" />
                <span
                    v-if="accountColor"
                    class="top-bar-nav-link-text"
                    :style="{ color: '#E0E0E5' }"
                >
                    Toolkit
                </span>
                <Skeleton v-else :width="48" :height="12" class="top-bar-nav-link-text" />
            </div>
        </div>

        <div v-if="!mobileDomainSelectorOpen" class="domain-top-nav-bar-right">
            <router-link
                id="navDomainSettings"
                :to="{ name: Routes.Notes }"
                class="top-nav-bar-link secondary"
                tabindex="-1"
                @click="isMobileContext ? closeMobileNav(Routes.Notes) : null"
            >
                <span v-if="accountColor" :style="{ color: accountColor }">Notes</span>
                <Skeleton v-else :width="43" :height="12" />
            </router-link>

            <router-link
                v-if="
                    (!isMeta && !isMicrosoft) ||
                    (isMicrosoft &&
                        betaFeatures?.microsoftBetaOnboarding &&
                        betaFeatures?.microsoftGamma)
                "
                id="navDomainSettings"
                :to="{ name: Routes.DomainSettings }"
                class="top-nav-bar-link secondary"
                tabindex="-1"
                @click="isMobileContext ? closeMobileNav(Routes.DomainSettings) : null"
            >
                <span v-if="accountColor" :style="{ color: accountColor }">Settings</span>
                <Skeleton v-else :width="59" :height="12" />
            </router-link>
            <div v-else id="navDomainSettings" class="top-nav-bar-link link-disabled secondary">
                <span v-if="accountColor" :style="{ color: '#E0E0E5' }">Settings</span>
                <Skeleton v-else :width="59" :height="12" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, inject, computed, ref } from 'vue'
import { Account, Platform } from '@opteo/types'
import { useRouter, useRoute } from 'vue-router'
import { Avatar, Spacer, SelectArrowIcon } from '@opteo/components-next'
import sortBy from 'lodash-es/sortBy'

import { useAccountList } from '@/composition/user/useAccountList'
import { getDomainInitials } from '@/composition/domain/useDomain'
import { layout } from '@/composition/layouts/useLayouts'
import { useAccount } from '@/composition/account/useAccount'

import DomainSelector from '@/components/AccountCenter/DomainSelector.vue'
import ImprovementsIcon from '@/components/util/ImprovementsIcon.vue'
import PerformanceIcon from '@/components/util/PerformanceIcon.vue'
import ReportsIcon from '@/components/util/ReportsIcon.vue'
import TargetsIcon from '@/components/util/TargetsIcon.vue'
import Skeleton from '@/components/util/Skeleton.vue'
import { Routes } from '@/router/routes'
import { useUser } from '@/composition/user/useUser'

export default defineComponent({
    name: 'DomainTopNav',
    props: {
        isMobileContext: {
            type: Boolean as PropType<boolean>,
            default: false,
        },
    },
    setup() {
        const { accountId, accountName, accountColor, accountShadowColor, accountPlatform } =
            useAccount()
        const { sortedDomainList, mutateDomainList } = useAccountList()
        const { betaFeatures } = useUser()
        const domainSearch = ref('')
        const mobileDomainSelectorOpen = ref(false)

        const closeMobileDomainNav = inject<() => void>('closeMobileDomainNav', () => {})
        const router = useRouter()
        const route = useRoute()

        const closeMobileNav = (toRoute: string) => {
            const currentPaths = router.currentRoute.value.path.split('/')
            if (currentPaths.includes(toRoute)) {
                closeMobileDomainNav && closeMobileDomainNav()
            }
        }

        const miniDomainList = computed(() => {
            return sortBy(
                sortedDomainList.value?.filter(
                    domain =>
                        // searchForDomain(domainSearch.value, domain, domainId.value)
                        domain
                ),
                // @ts-ignore TODO: Update Domain.Info.last_visited to string
                d => -Date.parse(d.last_visited)
            ).slice(0, 20)
        })

        const changeDomain = async (accountId: Account.ID) => {
            // CLOSE THE SELECTOR
            router.push({
                name: route.name!,
                params: {
                    accountId,
                },
            })
            mutateDomainList(accountId, { lastVisitedAt: new Date().toISOString() })
        }

        // Temporary helper var used for disabling unsupported Microsoft beta pages
        const isGads = computed(() => accountPlatform.value === Platform.Platform.GoogleAds)
        const isMeta = computed(() => accountPlatform.value === Platform.Platform.MetaAds)
        const isMicrosoft = computed(() => accountPlatform.value === Platform.Platform.MicrosoftAds)

        return {
            accountId,
            accountName,
            accountColor,
            accountShadowColor,
            accountPlatform,
            isGads,
            isMicrosoft,
            isMeta,
            Routes,
            layout,
            closeMobileNav,
            miniDomainList,
            mobileDomainSelectorOpen,
            changeDomain,
            getDomainInitials,
            betaFeatures,
        }
    },
    components: {
        DomainSelector,
        ImprovementsIcon,
        PerformanceIcon,
        ReportsIcon,
        TargetsIcon,
        Skeleton,
        Avatar,
        Spacer,
        SelectArrowIcon,
    },
})
</script>

<style scoped>
/* Dynamic Style Block */
.top-nav-bar-link.router-link-active,
.top-nav-bar-link:not(.link-disabled):hover {
    background-color: v-bind(accountShadowColor);
}
</style>

<style scoped lang="scss">
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.domain-top-nav-bar.full-screen {
    display: none; //remove the whole thing in full-screen layout
}
.domain-top-nav-bar {
    @include grid;
    grid-template-columns: 1fr auto 1fr;
    height: 100%;
}
.domain-top-nav-bar-center {
    @include flex;
    @include items-center;
}
.domain-top-nav-bar-right {
    @include flex;
    @include items-center;
    justify-self: end;
}

.top-nav-bar-link {
    @include flex;
    @include items-center;
    @include f-7;
    @include fw-500;
    @include opteo-foreground;
    @include h-100;
    margin-left: 0.375rem;
    outline: none !important;
}
.top-nav-bar-link:first-child {
    @include ml-0;
}
.top-nav-bar-icon {
    @include opteo-foreground;
}
.top-bar-nav-link-text {
    display: none;
    @include ml-12;
}

// :hover and :active states

.top-nav-bar-link {
    height: 42px;
    @include ph-12;
    border-radius: 0.625rem;
    user-select: none;
}
.top-nav-bar-link.secondary {
    height: 36px;
}
.top-nav-bar-link:hover {
    opacity: 1;
}

.desktop-domain-selector {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.mobile-domain-selector {
    display: none;
}

.trigger {
    @include pa-24;
    border-bottom: 1px solid $opteo-light-gray;
    @include sticky;
    top: 0;
    @include bg-opteo-white;
    @include z-2;
    cursor: pointer;
    @include no-select;
}

.no-padding .domain-top-nav-bar-center,
.no-padding .domain-top-nav-bar-right {
    @include ma-0;
    @include pa-24;
    border-bottom: 1px solid $opteo-light-gray;
}

@media (min-width: $mq-1280-min) {
    .top-bar-nav-link-text {
        display: block;
        transform: translateY(-1px);
    }
}

@media screen and (max-width: $mq-767-max) {
    .client-name {
        @include f-7;
        @include fw-500;
        @include ml-20;
        transform: translateY(-1px);
        white-space: nowrap;
        max-width: 11.5rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .client-selector-arrow {
        @include ml-8;
        transform: translateX(0.125rem);
    }
    .desktop-domain-selector {
        display: none;
    }
    .mobile-domain-selector {
        display: block;
        .trigger {
            @include flex;
            @include items-center;
        }
    }
    .domain-top-nav-bar {
        display: flex;
        flex-direction: column;
    }
    .top-nav-bar-link {
        margin-left: unset;
        @include mb-8;
    }
    .top-nav-bar-link:last-child {
        @include mb-0;
    }
    .domain-top-nav-bar-center {
        @include flex;
        flex-direction: column;
        align-items: unset;
        @include mb-48;
    }
    .domain-top-nav-bar-right {
        @include flex;
        flex-direction: column;
        align-items: unset;
    }
    .top-bar-nav-link-text {
        display: block;
        transform: translateY(-1px);
    }
    .select-client-dropdown-client-mobile {
        @include pv-16;
        @include ph-24;
        @include flex;
        @include items-center;
        @include relative;
        @include pointer;
        border-bottom: 1px solid $opteo-light-gray;
    }
    .select-client-dropdown-client-name {
        @include f-7;
        @include fw-500;
        @include lh-150;
        @include ml-20;
        max-width: 10.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .select-client-dropdown-client-imps {
        @include f-10;
        @include fw-500;
        @include lh-150;
        @include br-999;
        @include flex;
        @include items-center;
        @include justify-center;
        width: 1.5rem;
        height: 1.5rem;
        box-shadow: $opteo-shadow;
        margin-left: 0.5rem;
    }
}
</style>
